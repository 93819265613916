import React, {useState} from 'react';
import {Container, Typography, TextField, Button, Box} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {useLoginStatus} from "./LoginStatusContext ";

const Login = () => {
    const {isLoggedIn, login, logout} = useLoginStatus();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const handleLogin = async () => {
        login(username, password)
    };
    const handleLogout = async () => {
        logout()
    };
    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={5}
            >
                <AccountCircle fontSize="large"/>

                {isLoggedIn ? (<>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogout}
                        fullWidth
                        mt={2}
                    >
                        Logout
                    </Button>
                </>) : (<>
                    <Typography variant="h5" component="h2" mt={2}>
                        Login
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        fullWidth
                        mt={2}
                    >
                        Login
                    </Button>


                </>)}

            </Box>
        </Container>
    );
};

export default Login;