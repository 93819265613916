import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, Button, Container, Grid, MenuItem, Select} from "@mui/material";
import CreatableAutocomplete from "./CreatableAutocomplete";

const FormComponent = (props) => {


    const [excelGroupName, setExcelGroupName] = useState(props.record.excel_group || '')
    const [theaterGroup, setTheaterGroup] = useState(props.record.theater_group || '')
    const {onSaved} =props

    const fetchData = async () => {
        try {


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        // Fetch your data from an API endpoint using Axios or fetch
        // Replace the URL with your API endpoint


        fetchData();
    }, []);


    const save = () => {
        const apiUrl = '/api/update-theater-group';
        const formData = new FormData();
        formData.append('group_name', excelGroupName);
        formData.append('theater_group', theaterGroup);
        fetch(apiUrl, {
            method: 'POST',
            body: formData, // Send the FormData object as the request body
        })
            .then(response => {
                // Check if the response status is OK (200)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse the response body as JSON or other appropriate format
                return response.json();
            })
            .then(data => {
                // Handle the response data here

                onSaved()
                // You can perform further actions with the data here
            })
            .catch(error => {
                // Handle errors, such as network issues or invalid JSON
                console.error('Fetch error:', error);
            });
    }
     const handleTheaterGroupChange = (event, newValue) => {
        setTheaterGroup(newValue);
    };
    const handleExcelGroupChange = (event, newValue) => {
        setExcelGroupName(newValue);
    };

    return (
        <form>
            <Container sx={{paddingTop: 5}}>
                <Grid container space={3}>
                    <Grid container md={12}>
                        <Grid md={6}>
                            <TextField
                                id="excel_group"
                                label="電影院名稱"
                                value={excelGroupName}
                                onChange={handleExcelGroupChange}></TextField>
                        </Grid>
                        <Grid md={6}>
                            <TextField
                                id="theater_group"
                                label="電影院名稱"
                                value={theaterGroup}
                                onChange={handleExcelGroupChange}></TextField>

                        <Button onClick={save} variant="contained" color="success">儲存</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </form>
    );
};

export default FormComponent;