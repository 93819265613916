import logo from './logo.svg';
import './App.css';

import {LoadingProvider} from "./Components/LoadingContext";
import Loading from "./Components/Loading";

import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';

import React from 'react';

import {LoginStatusProvider} from "./Components/LoginStatusContext ";
import LoginRouterProvider from "./Components/LoginRouterProvider";


const LinkBehavior = React.forwardRef(function (props, ref) {
    const {href, ...other} = props;
    // Map href (Material UI) -> to (react-router)
    return React.createElement(RouterLink, {ref: ref, to: href, ...other});
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
    },
});


function App() {
     document.title ='Sony 電影'
    return (
        <div>

            <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <LoginStatusProvider>
                    <LoadingProvider>
                        <Loading></Loading>
                        <LoginRouterProvider></LoginRouterProvider>

                    </LoadingProvider>
                </LoginStatusProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
