import React, {useState, useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {useLoading} from "./LoadingContext";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';

export default function MergeMovieInfo() {
    const [data, setData] = useState([]);
    const [sdate, setSData] = useState(dayjs().subtract(2, 'month'));

    const {showLoading, hideLoading} = useLoading();
    const loadData= ()=>{
        showLoading();
         fetch(`/api/merge-movie-info?s=${sdate.format('YYYY-MM-DD')}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
                hideLoading()
            })
    }
    useEffect(() => {
       loadData();
    }, [sdate])

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"zh-cn"}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>

                    <DatePicker
                        label="開始時間"
                        value={sdate}
                        onChange={(newValue) =>{ setSData(newValue);}}
                    />

                </DemoContainer>
            </LocalizationProvider>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>電影</TableCell>
                            <TableCell>類型</TableCell>
                            <TableCell>語言</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(row => (
                            <TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.type.join(',')}</TableCell>
                                <TableCell>{row.language.join(',')}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
}