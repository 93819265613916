import React, {useState, useEffect} from 'react';
import {useLoading} from "./LoadingContext";
import {Line} from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

// colors
const colors = [
    'rgb(201, 99, 132)',
    'rgb(144, 255, 66)',
    'rgb(0, 99, 255)',
    'rgb(99,255,138)',
    'rgb(0, 199, 0)',
    'rgb(255, 0, 66)',
    'rgb(155, 99, 0)',
    'rgb(0, 0, 255)',
    'rgb(255, 99, 82)',
    'rgb(199, 0, 255)',
    'rgb(255, 255, 0)', // yellow
    'rgb(0, 255, 255)', // cyan
    'rgb(255, 0, 255)', // magenta
    'rgb(128, 128, 128)', // gray
    'rgb(0, 0, 0)', // black
    'rgb(255, 255, 255)', // white
    'rgb(255, 165, 0)', // orange
    'rgb(128, 0, 0)', // maroon
    'rgb(0, 128, 0)', // green
    'rgb(0, 0, 128)', // navy
    'rgb(128, 0, 128)', // purple
    'rgb(255, 192, 203)', // pink
    'rgb(240, 230, 140)', // khaki
    'rgb(173, 216, 230)', // lightblue
    'rgb(255, 215, 0)', // gold
    'rgb(139, 69, 19)', // brown
    'rgb(0, 191, 255)', // deepskyblue
    'rgb(255, 105, 180)', // hotpink
    'rgb(60, 179, 113)', // mediumseagreen
    'rgb(255, 140, 0)' // darkorange
];

// bColors
const bColors = [
    'rgba(201, 99, 132, 0.5)',
    'rgba(144, 255, 66, 0.5)',
    'rgba(0, 99, 255, 0.5)',
    'rgb(99,255,138,0.5)',
    'rgba(0, 199, 0, 0.5)',
    'rgba(255, 0, 66, 0.5)',
    'rgba(155, 99, 0, 0.5)',
    'rgba(0, 0, 255, 0.5)',
    'rgba(255, 99, 82, 0.5)',
    'rgba(199, 0, 255, 0.5)',
    'rgba(255, 255, 0, 0.5)', // yellow
    'rgba(0, 255, 255, 0.5)', // cyan
    'rgba(255, 0, 255, 0.5)', // magenta
    'rgba(128, 128, 128, 0.5)', // gray
    'rgba(0, 0, 0, 0.5)', // black
    'rgba(255, 255, 255, 0.5)', // white
    'rgba(255, 165, 0, 0.5)', // orange
    'rgba(128, 0, 0, 0.5)', // maroon
    'rgba(0, 128, 0, 0.5)', // green
    'rgba(0, 0, 128, 0.5)', // navy
    'rgba(128, 0, 128, 0.5)', // purple
    'rgba(255, 192, 203, 0.5)', // pink
    'rgba(240, 230, 140, 0.5)', // khaki
    'rgba(173, 216, 230, 0.5)', // lightblue
    'rgba(255, 215, 0, 0.5)', // gold
    'rgba(139, 69, 19, 0.5)', // brown
    'rgba(0, 191, 255, 0.5)', // deepskyblue
    'rgba(255, 105, 180, 0.5)', // hotpink
    'rgba(60, 179, 113, 0.5)', // mediumseagreen
    'rgba(255, 140, 0, 0.5)' // darkorange
];

function padArray(arr, minLength) {
    if (arr.length < minLength) {
        const padding = new Array(minLength - arr.length).fill(0);
        return arr.concat(padding);
    }
    return arr;
}


export default function MovieMonitor() {
    const [data, setData] = useState({
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        datasets: [
            {
                label: 'test',
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
                borderColor: colors[0],
                backgroundColor: bColors[0],
            }
        ],
    });


    const {showLoading, hideLoading} = useLoading();
    const loadData = () => {
        showLoading();
        fetch(`/api/calculate-movie-count`)
            .then(response => response.json())
            .then(respdata => {
                let index = 0;
                let config = {
                    labels: respdata.labels,
                    datasets: [],
                }

                for (let key in respdata.data) {

                    config.datasets.push({
                        label: key,
                        data: respdata.data[key],
                        borderColor: colors[index],
                        backgroundColor: bColors[index],
                    });
                    index++;
                }


                setData(config);
                hideLoading()
            })
    }
    useEffect(() => {
        loadData();
    }, [])

    return (
        <div>

            <Line data={data}/>
        </div>

    );
}