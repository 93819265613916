import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./Root";
import Login from "./Login";
import MovieRunLog from "./MovieRunLog";
import MovieNotMap from "./MovieNotMap";
import MergeMovieInfo from "./MergeMovieInfo";
import SimilarlyMovieInfo from "./SimilarlyMovieInfo";
import React from "react";
import {useLoginStatus} from "./LoginStatusContext ";
import MovieMonitor from "./MovieMonitor";
import TheaterMap from "./TheaterMap";


export default function LoginRouterProvider() {
    const {isLoggedIn, login, logout} = useLoginStatus();

    let routerMap=[
        {
            path: "/",
            element: <Root/>,
            children: [
                {
                    path: "/",
                    element: <Login/>
                },
                {
                    path: "/log",
                    element: <MovieRunLog/>,
                },
                {
                    path: "/not-map",
                    element: <MovieNotMap/>,
                },
                {
                    path: "/merge-info",
                    element: <MergeMovieInfo/>,
                },
                {
                    path: "/similarly-movie",
                    element: <SimilarlyMovieInfo/>
                },
                 {
                    path: "/movie-monitor",
                    element: <MovieMonitor/>
                },
                {
                    path: "/theater-map",
                    element: <TheaterMap/>
                }
            ]
        },

    ]


    const router = createBrowserRouter(routerMap);


    return (<>
        <RouterProvider router={router}/>
    </>)
}

