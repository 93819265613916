import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Checkbox, Input,
    MenuItem, Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from '@mui/material';
import {useLoading} from "./LoadingContext";
import MovieMapForm from "./MovieMapForm";
import TheaterMapForm from "./TheaterMapForm";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TheaterMap() {
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [type, setType] = useState("all");
    const [showNull, setShowNull] = useState(true);
    const {showLoading, hideLoading} = useLoading();
    const [modifyRecord, setModifyRecord] = React.useState({});
    const [open, setOpen] = React.useState(false);

    const [filterName, setFilterName] = React.useState("");
    const [filterTargetName, setFilterTargetName] = React.useState("");
    const nameTimeoutId = useRef(null)
    const targetNameTimeoutId = useRef(null)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const loadData = () => {
        showLoading();
        let url = `/api/get-theater-group`

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data);
                filterContent(data)
                hideLoading();
            })
    }
    useEffect(() => {

        loadData()
    }, [type, showNull])
   useEffect(() => {

        filterContent(data)
    }, [filterTargetName,filterName])



    const edit = (record) => {

        setModifyRecord(record)
        handleOpen()
    }

    const onSaved = () => {
        handleClose()

        setModifyRecord(null)
        loadData()
    }

    const filterContent=(data)=>{
        let filteredData=data
        if(filterName!=='') {
            filteredData = filteredData.filter(mapinfo => {
                return mapinfo.excel_group.includes(filterName)
            })
        }
        if(filterTargetName!=='') {
            filteredData = filteredData.filter(mapinfo => {
                return mapinfo.theater_group.includes(filterTargetName)
            })
        }
            setFilterData((filteredData))
    }

    const handleFilterName=(e) => {

          if(nameTimeoutId.current) {
              clearTimeout(nameTimeoutId.current);
          }

        nameTimeoutId.current = setTimeout(() => {
          setFilterName(e.target.value)
        }, 500);

        // filterContent(data)
    }
    const handleFilterTargetName=(e) => {
        if(targetNameTimeoutId.current) {
            clearTimeout(targetNameTimeoutId.current);
        }

        targetNameTimeoutId.current = setTimeout(() => {
          setFilterTargetName(e.target.value)
        }, 500);
        
        // filterContent(data)
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TheaterMapForm record={modifyRecord} onSaved={onSaved}></TheaterMapForm>
                </Box>
            </Modal>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Excel群組</TableCell>
                            <TableCell>戲院群組</TableCell>
                            <TableCell>操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key="filter">
                            <TableCell><TextField onChange={handleFilterName}></TextField></TableCell>
                            <TableCell><TextField onChange={handleFilterTargetName}></TextField></TableCell>
                        </TableRow>
                        {filterData.map(row => (
                            <TableRow key={row.theater_group + row.excel_group}>
                                <TableCell>{row.excel_group}</TableCell>
                                <TableCell>{row.theater_group}</TableCell>
                                <TableCell><Button onClick={edit.bind(null, row)} variant="contained"
                                                   color="success">修改</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
}