import React from 'react';
import { useLoading } from './LoadingContext';
import CircularProgress from '@mui/material/CircularProgress';

function Loading() {
  const { loading } = useLoading();

  return (
    loading && (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    )
  );
}

export default Loading;