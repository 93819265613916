import {createContext, useContext, useEffect, useState} from 'react';

const LoginStatusContext  = createContext();

export const useLoginStatus = () => {
  return useContext(LoginStatusContext );
};

export const LoginStatusProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
 useEffect( ()=> {
        fetch(`/api/isLogin`)
            .then(response => response.json())
            .then(data => {
                setIsLoggedIn(data.login);
            })


 })
  const login = async (username,password)  => {
      const apiUrl = '/api/login';
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        await fetch(apiUrl, {
            method: 'POST',
            body: formData, // Send the FormData object as the request body
        })
            .then(response => {
                // Check if the response status is OK (200)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse the response body as JSON or other appropriate format
                return response.json();
            })
            .then(data => {
                // Handle the response data here
                if(data.success===true){

                    setIsLoggedIn(true);
                }else{
                    alert("登入失敗")
                }
                // You can perform further actions with the data here
            })
            .catch(error => {
                // Handle errors, such as network issues or invalid JSON
                console.error('Fetch error:', error);
            });


  };

  const logout = async () => {
        await fetch(`/api/logout`)
            .then(response => response.json())
            .then(data => {
                setIsLoggedIn(false);
            })
  };

  return (
    <LoginStatusContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </LoginStatusContext.Provider>
  );
};